import React from 'react'
import config from '../config'
import Eventlist from '../components/Eventlist'
import Loading from '../components/Loading'
import * as Firebase from 'firebase/app'
import 'firebase/database'

class EventlistContainer extends React.Component {
  constructor(props) {
    super(props)
    Firebase.initializeApp(config.firebase)
    this.state = {
      loading: true,
      events: [],
      filters: props.pageParams
    }
  }

  componentDidMount() {
    this.fetchEvents()
  }

  fetchEvents() {
    // Event date is later than yesterday
    const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date()).toISOString()

    const db = Firebase.database().ref('/items/')
    db.orderByChild('date')
      .startAt(yesterday)
      .once('value', snapshot => {
        const events = this.eventsTransform(snapshot.val())
        this.setState({ events, loading: false })
      })
  }

  eventsTransform(events) {
    if (!events || !Object.keys(events)) return []

    // Transform object into an array
    let eventObj = Object.keys(events).reduce((all, id) => {
      all.push({ id, ...events[id] })
      return all
    }, [])

    // Filter Events based on event type
    const { filters } = this.state
    if (filters.id) eventObj = eventObj.filter(item => item.event == filters.id)

    // Sort list by date
    eventObj.sort((a, b) => {
      if (a.date < b.date) return -1
      if (a.date > b.date) return 1
      return 0
    })

    return eventObj
  }

  render() {
    const { loading, events, filters } = this.state
    if (loading) return <Loading />
    return <Eventlist events={events} filters={filters} />
  }
}

export default EventlistContainer
