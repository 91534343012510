import React from 'react'
import Event from './Event'

const Eventlist = props => {
  const { events } = props
  // console.log(events[0])
  return (
    <div className="com-eventlist">
      {events.map((event, index) => (
        <Event key={event.id} event={event} index={index} filters={props.filters} />
      ))}
    </div>
  )
}

export default Eventlist
