const config = {
  firebase: {
    apiKey: 'AIzaSyAR5kkRqKHXtViTZ7GBrjUtArbbQfDZNoE',
    projectId: 'colors-events',
    databaseURL: 'https://colors-events.firebaseio.com',
    authDomain: 'colors-events.firebaseapp.com',
  },
}

export default config
