import React from 'react'
import { formatDate } from '../libs/helpers'

const Event = (props) => {
  // const { id, city, date, event, state, ticket, time, venue } = props.event
  const { city, date, state, ticket, venue, title, event, type } = props.event
  const { id = null, style = null } = props.filters

  const format = formatDate(date)

  // Image
  const imageUrl = `assets/images/${event}.png`

  // Animation
  const animationDelay = props.index * 0.075 + 's'
  const styleObj = { animationDelay }

  return (
    <article className="com-event" style={styleObj}>
      <div className="date">
        {format.mmmm && <div className="month">{format.mmmm}</div>}
        {format.dd && <div className="day">{format.dd}</div>}
        {!format.mmmm && <div className="month">{date}</div>}
      </div>
      <div className="middle">
        <div className="name">
          {style ? <img src={imageUrl} alt={title} /> : title}
          {type && <div className="type">{type}</div>}
        </div>
        <div className="location">
          {city} {state ? `, ${state}` : ''}
        </div>
        <div className="venue">{venue}</div>
      </div>
      <div className="ticket">
        <a href={ticket}>Tickets</a>
      </div>
    </article>
  )
}

export default Event
