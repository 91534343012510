import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

const rootElement = document.getElementById('event-list')

const pageParams = {
  id: rootElement.getAttribute('data-id'),
  style: rootElement.getAttribute('data-style')
}

ReactDOM.render(<App pageParams={pageParams} />, rootElement)
