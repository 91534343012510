import React from 'react'
import EventlistContainer from './containers/EventlistContainer'

function App(props) {
  return (
    <div className="com-eventcontainer">
      <EventlistContainer {...props} />
    </div>
  )
}

export default App
