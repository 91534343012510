import dayjs from 'dayjs'

/**
 * Date for calendar display
 */
export function formatDate(date) {
  const zoned = dayjs(new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' }))
  const response = {
    mmmm: validate(dayjs(zoned).format('MMMM')),
    dd: validate(dayjs(zoned).format('D')),
    ddd: validate(dayjs(zoned).format('ddd')),
  }
  return response
}

function validate(date) {
  if (date == 'Invalid Date') return false
  if (!date) return false
  return date
}
